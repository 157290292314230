var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "close-on-click-modal": false,
        "append-to-body": "",
        width: "960px",
        "before-close": _vm.close,
        top: _vm.top,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "dia-tit",
          staticStyle: { display: "flex", "align-items": "center" },
          attrs: { slot: "title" },
          slot: "title",
        },
        [_c("span", [_vm._v("通知消息")])]
      ),
      _c("div", {
        staticStyle: { "max-height": "650px", "overflow-y": "auto" },
        domProps: { innerHTML: _vm._s(_vm.notifyInfo.richText) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }