import $axios from '@/utils/request'

// 查询是否有弹窗消息
export function getPageBannerOnTeacher (data) {
  const url = '/merchant/pageBanner/getPageBannerInfo'
  return $axios.fGet(url, data)
}

// 历史消息列表
export function listNoticeOnTeacher (data) {
  const url = '/merchant/pageBanner/listNoticeOnMerchant'
  return $axios.fPost(url, data)
}
