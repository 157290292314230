<template>
  <el-dialog
    :visible.sync="show"
    :close-on-click-modal="false"
    append-to-body
    width="960px"
    :before-close="close"
    :top='top'
  >
    <div slot="title" class="dia-tit" style="display: flex;align-items: center;">
      <span>通知消息</span>
    </div>
    <div  v-html="notifyInfo.richText" style="max-height: 650px;overflow-y: auto;"></div>
  </el-dialog>
</template>

<script>
export default {
  data(){
    return{
      top:'0vh'
    }
  },
  watch:{
    notifyInfo () {
      if (this.notifyInfo.richText.length> 800 || this.notifyInfo.richText.indexOf('img') > 0) {
        this.top = '0vh'
      }else{
        this.top = '30vh'
      }
    },
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    notifyInfo:{
      default: {},
      type: Object
    },
  },
  methods: {
    // 关闭
    close () {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../style/dialog.scss";
::v-deep img{
  width:100%;
}
</style>
