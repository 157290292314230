var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "notify" },
        [
          _c("img", {
            staticStyle: { width: "30px", "margin-right": "10px" },
            attrs: { src: require("../../assets/images/new.png") },
          }),
          _vm.notifyList.length === 0
            ? _c("span", { staticStyle: { cursor: "pointer" } }, [
                _vm._v("欢迎使用可达鸭教学系统~"),
              ])
            : _c(
                "span",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.openDialogMessage(_vm.notifyList[0])
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.notifyList[0].title))]
              ),
          _c(
            "span",
            { staticClass: "moreMessage", on: { click: _vm.moreMessage } },
            [_vm._v("更多消息>>")]
          ),
          _c("dialog-message", {
            attrs: { show: _vm.dialogMessageFlag, notifyInfo: _vm.notifyInfo },
            on: {
              close: function ($event) {
                _vm.dialogMessageFlag = false
              },
            },
          }),
          _c(
            "el-drawer",
            {
              staticStyle: { "margin-top": "50px" },
              attrs: {
                size: "25%",
                "with-header": false,
                modal: false,
                visible: _vm.drawer,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c("div", { staticClass: "elCardFixed" }, [_vm._v(" 通知中心 ")]),
              _c(
                "div",
                { staticStyle: { "margin-top": "60px" } },
                _vm._l(_vm.notifyList, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "itemNotify" },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "30px",
                          height: "30px",
                          background: "#a6a6a6",
                          "border-radius": "50%",
                          padding: "3px",
                        },
                        attrs: {
                          src: require("../../assets/logo/sm_logo.png"),
                        },
                      }),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _vm._v(_vm._s(item.title) + " "),
                            item.degree === "1"
                              ? _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-left": "10px",
                                  },
                                  attrs: {
                                    src: require("../../assets/images/new.png"),
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "margin-top": "10px",
                              cursor: "pointer",
                              "max-height": "100px",
                              overflow: "hidden",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openDialogMessage(item)
                              },
                            },
                          },
                          [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(item.richText) },
                            }),
                          ]
                        ),
                        _c("div", { staticClass: "contenttime" }, [
                          _vm._v(_vm._s(item.createTime)),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c("el-pagination", {
                staticClass: "paginationClass",
                attrs: {
                  "current-page": _vm.pagination.currentPage,
                  "page-sizes": _vm.pagination.pageSizes,
                  "page-size": _vm.pagination.pageSize,
                  layout: _vm.pagination.layout,
                  total: _vm.pagination.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.systemCode === "merchant"
        ? _c("course-times", {
            directives: [
              {
                name: "has",
                rawName: "v-has",
                value: "home:courseAccountStatistic",
                expression: "'home:courseAccountStatistic'",
              },
            ],
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }