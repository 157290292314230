<template>
  <div>
    <div class="notify">
      <img src="../../assets/images/new.png" style="width: 30px;margin-right: 10px;"/>

      <span style="cursor: pointer" v-if="notifyList.length === 0">欢迎使用可达鸭教学系统~</span>

      <span style="cursor: pointer" v-else @click="openDialogMessage(notifyList[0])">{{ notifyList[0].title }}</span>

      <span class="moreMessage" @click="moreMessage">更多消息>></span>

      <dialog-message :show="dialogMessageFlag" :notifyInfo="notifyInfo"
                      @close="dialogMessageFlag=false"></dialog-message>
      <el-drawer
        size="25%"
        :with-header="false"
        :modal="false"
        style="margin-top: 50px;"
        :visible.sync="drawer"
      >
        <div class="elCardFixed">
          通知中心
        </div>
        <div style="margin-top: 60px;">
          <div class="itemNotify" v-for="item in notifyList" :key="item.id">
            <img src="../../assets/logo/sm_logo.png"
                 style="width: 30px;height: 30px;background: #a6a6a6;border-radius: 50%;padding: 3px;">
            <div class="content">
              <div style="display: flex;align-items: center">{{ item.title }}
                <img v-if="item.degree === '1'" src="../../assets/images/new.png"
                     style="width: 20px;margin-left: 10px;"/>
              </div>
              <div style="font-size: 14px;margin-top: 10px;cursor: pointer;max-height: 100px;overflow: hidden"
                   @click="openDialogMessage(item)">
                <div style="" v-html="item.richText"></div>
              </div>
              <div class="contenttime">{{ item.createTime }}</div>
            </div>
          </div>
        </div>
        <el-pagination
          class="paginationClass"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="pagination.total"
        ></el-pagination>
      </el-drawer>
    </div>
    <course-times v-if="systemCode === 'merchant'" v-has="'home:courseAccountStatistic'"></course-times>
  </div>
</template>

<script>
import dialogMessage from '../public/user/dialogMessage'
import CourseTimes from '../course/courseTimes'
import { getPageBannerOnTeacher, listNoticeOnTeacher } from '@/api/notify'

export default {
  data () {
    return {
      systemCode:'',
      dialogMessageFlag: false,
      notifyInfo: {},
      notifyList: [],
      drawer: false,
      dialogNotifyList: [],
      clickMore: false,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },

    }
  },
  components: { dialogMessage,CourseTimes },
  mounted () {
    this.systemCode = localStorage.getItem('systemCode')
    if(localStorage.getItem('systemCode') === 'merchant') {
      this.getPageBannerOnTeacher()
    }
  },
  created () {
    if(localStorage.getItem('systemCode') === 'merchant') {
      this.clickMore = false
      this.listNoticeOnTeacher()
    }
    let routers = localStorage.getItem('routerList')
    if (routers) {
      routers = JSON.parse(routers)
      this.$router.push({ path: routers[0].path })
    }
  },
  methods: {
    moreMessage () {
      this.clickMore = true
      this.listNoticeOnTeacher()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.listNoticeOnTeacher()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.listNoticeOnTeacher()
    },
    async getPageBannerOnTeacher () {
      const response = await getPageBannerOnTeacher({})
      if (response && response.state === 'success') {
        this.dialogNotifyList = response.body
        if (this.dialogNotifyList && this.dialogNotifyList.length !== 0) {
          this.notifyInfo = this.dialogNotifyList[0]
          this.dialogMessageFlag = true
        }
      }
    },
    async listNoticeOnTeacher () {
      const response = await listNoticeOnTeacher({
        needCount: true,
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.currentPage,
      })
      if (response && response.state === 'success') {
        this.notifyList = response.body.list
        this.pagination.total = response.body.total
        if (this.clickMore) {
          if (this.notifyList.length !== 0) {
            this.drawer = true
          } else {
            window.$msg('暂无更多通知消息', 2)
          }
        }
      }
    },
    openDialogMessage (data) {
      this.notifyInfo = data
      this.dialogMessageFlag = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";

.home-card {
  margin-bottom: 20px;
}

.home-card {
  padding: 10px;
  background: #fff;
}

.home-table {
  margin: 25px auto 45px;
}

.home-tit {
  font-size: 15px;
  text-align: left;
  color: #333333;
  font-weight: 400;
}

.home-refresh {
  display: inline-block;
  font-weight: 600;
  vertical-align: sub;
  margin-left: 20px;
  margin-top: 2px;
}

.slider-title {
  line-height: 20px;
  font-size: 12px;
}

.slider-bar-box {
  text-align: left;
}

.slider-bar {
  line-height: 20px;
  display: inline-block;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background-color: #f5c319;
}

.notify {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  padding: 10px;
  position: fixed;
  width: 100%;
  margin-left: -30px;
  margin-top:-23px;
}

.el-slider__bar {
  background-color: #f5c319 !important;
}

.elCardFixed {
  padding: 15px;
  border-bottom: #e3e3e3 solid 1px;
  position: fixed;
  background: #ffffff;
  width: 100%;
}

.itemNotify {
  padding: 10px;
  border-bottom: 1px solid #e3e3e3;
  display: flex;
}

.content {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.contenttime {
  font-size: 14px;
  color: #909399;
  margin-top: 25px;
}

.moreMessage {
  cursor: pointer;
  font-size: 12px;
  margin-left: 15px;
  color: #F7CF47
}

.paginationClass {
  background: #ffffff;
  position: absolute;
  bottom: -24px;
  width: 100%;
  text-align: center;
}

::v-deep img {
  width: 100%;
}
</style>

<style lang="scss">
.el-drawer__body {
  overflow: auto;
}
</style>
